.slide-pane__overlay.overlay-after-open {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(87, 100, 110, 0.33);
  outline-offset: 2px;
  transition: opacity 0.5s ease-out;
}
.slide-pane {
  &.candidate-slider {
    max-width: none !important;
    min-width: 620px !important;

    .slider-body {
      padding: 0;

      .slider-header-row {
        background-color: $slider-header-background;
      }

      .slider-body-row {
        margin-top: 1.5em;
      }
    }

    .candidate-name,
    .candidate-email,
    .candidate-phone {
      .detail-value-block {
        align-items: center;
        display: flex;
        font-weight: $font-weight-bold;

        svg {
          width: 0.75em;
        }
      }
    }

    .candidate-warnings .badge,
    .candidate-tags .react-tags__selected-tag {
      border-radius: 2rem;
      padding: 0.05em 1em;
    }

    .application-details {
      .dd-menu {
        width: 175px;
      }

      .ellipsis-opts-btn {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;

        svg {
          margin-left: 0.5em;
        }
      }

      .cv-attachment {
        padding-top: 3.15em;

        .detail-value {
          padding: 0;
        }
      }
    }

    @media (min-width: 800px) {
      width: 98% !important;
    }

    @media (min-width: 1200px) {
      width: 90% !important;
    }

    @media (min-width: 1600px) {
      width: 80% !important;
    }

    @media (min-width: 1800px) {
      width: 70% !important;
    }

    @media (min-width: 2000px) {
      width: 60% !important;
    }
  }
}

/* SummarySection.css */
.app-summary-section {
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;
  box-shadow:
    inset 0 2px 4px 0 rgba(255, 255, 255, 0.9),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.03);
}

.app-summary-section__header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.app-summary-section__title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.app-summary-section__ai-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f3f0ff;
  color: #6d28d9;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.app-summary-section__text {
  color: #374151;
  line-height: 1.6;
  margin-bottom: 20px;
  font-size: 14px;
}

.app-summary-section__section-header {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4b5563;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.app-summary-section__icon {
  color: #6b7280;
}

.app-summary-section__pills {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 8px;
}

.app-summary-section__skill-pill {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background-color: #5B7BE5;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.app-summary-section__skill-pill:hover {
  background-color: #4A6AD4;
}

.app-summary-section__year-badge {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 2px 6px;
  border-radius: 10px;
  font-size: 12px;
}

.app-summary-section__strength-pill {
  display: inline-flex;
  align-items: center;
  background-color: #f3f0ff;
  color: #6d28d9;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.app-summary-section__strength-pill:hover {
  background-color: #ede9fe;
}

.app-summary-section__show-more {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #6b7280;
  font-size: 14px;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.app-summary-section__show-more:hover {
  color: #374151;
}

.app-summary-section__show-more svg {
  transition: transform 0.2s ease;
}

.app-summary-section__show-more svg.rotate-180 {
  transform: rotate(180deg);
}

.app-summary-section__skills,
.app-summary-section__strengths {
  margin-bottom: 20px;
}

.app-summary-section__strengths {
  margin-bottom: 0;
}
